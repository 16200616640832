<template>
  <Layout>
    <div class="container">
      <div class="row mt-3">
        <bootstrapAlert/>
        <div class="col-lg-3 col-md-3">
          <div class="detail-logo-card">
            <img src="@/assets/images/ico-detail.png" height="185" width="216" alt="Detail Logo">
          </div>
        </div>
        <div class="col-lg-9 col-md-9">
          <div class="font-title" v-html="this.loan.borrowername"></div>
          <div class="font-sub-title">
            {{this.loan.businesstype}}
          </div>

          <div class="method-info mt-2">
            Method: {{this.loan.processingmethod}}
          </div>
          <div class="location-info mt-3">
            <img src="@/assets/images/ico-location.png" height="30" width="30" alt="location">
            <span>
              {{this.loan.borrowercity}}, {{this.loan.borrowerstate}}
            </span>
          </div>

          <div class="font-text mt-3">
            <span v-html="this.loan.borrowername"></span> is a {{this.loan.businesstype}} located at <span v-html="this.loan.borroweraddress"></span>
            in {{this.loan.borrowercity}}, {{this.loan.borrowerstate}} that received a Coronavirus-related PPP loan from the SBA of
            <b class="text-success">{{usDollar(this.loan.currentapprovalamount)}}</b> in {{toDateString(this.loan.dateapproved)}}.
          </div>
        </div>
      </div>


      <!-- Accordions with Plus Icon -->
      <div class="accordion custom-accordionwithicon-plus mt-5 mb-5" id="accordionWithplusicon">
        <div class="accordion-item">
          <h2 class="accordion-header" id="loan-info">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#loan-info-content"
                    aria-expanded="true" aria-controls="loan-info-content">
              {{$t("ppp_loan_information")}}
            </button>
          </h2>
          <div id="loan-info-content" class="accordion-collapse collapse show" aria-labelledby="loan-info" data-bs-parent="#accordionWithplusicon">
            <div class="accordion mt-2 mb-2" id="sub-accordionWithplusicon">
              <div class="sub-accordion-item" v-for="(item, i) of this.loans" v-bind:key="i">
                <h2 class="sub-accordion-header">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#loan-info-content1'+i"
                          aria-expanded="true" :aria-controls="'#loan-info-content1'+i">
                    Loan {{i >= 10? (i+1): '0' + (i+1)}}
                  </button>
                </h2>
                <div class="container">
                  <div class="row ps-2 pe-2">
                    <div class="col-md-3 col-lg-3">
                      <div class="font-text-secondary">
                        Loan size
                      </div>
                      <div class="font-title">
                        {{usDollar(item.currentapprovalamount)}}
                      </div>
                    </div>
                    <div class="col-md-3 col-lg-3">
                      <div class="font-text-secondary">
                        {{$t("loan_approved")}}
                      </div>
                      <div class="font-title">
                        {{toDateString(item.dateapproved)}}
                      </div>
                    </div>
                    <div class="col-md-3 col-lg-3">
                      <div class="font-text-secondary">
                        {{$t("jobs_retained")}}
                      </div>
                      <div class="font-title">
                        {{item.jobsreported}}
                      </div>
                    </div>
                    <div class="col-md-3 col-lg-3">
                      <div class="font-text-secondary">
                        {{$t("loan_status")}}
                      </div>
                      <div class="font-title">
                        {{item.loanstatus}}
                      </div>
                    </div>
                    <div class="font-text-secondary mt-2">
                      {{$t("lender")}}
                    </div>
                    <div class="font-title">{{item.servicinglendername}}</div>
                  </div>
                </div>

                <div :id="'loan-info-content1'+i" class="accordion-collapse collapse" aria-labelledby="loan-info" data-bs-parent="#sub-accordionWithplusicon">
                  <div class="container pb-1">
                    <div class="row mt-1 ps-2 pe-2">

                      <p class="money-will-go">Where Applicants said the money will go</p>

                      <div class="money-element">
                        Payroll
                        <span class="value">{{usDollar(item.payroll_proceed)}}</span>
                      </div>

                      <div class="money-element">
                        Utilities
                        <span class="value">{{usDollar(item.utilities_proceed)}}</span>
                      </div>

                      <div class="money-element">
                        Mortgage Interest
                        <span class="value">{{usDollar(item.mortgage_interest_proceed)}}</span>
                      </div>

                      <div class="money-element">
                        Health Care
                        <span class="value">{{usDollar(item.health_care_proceed)}}</span>
                      </div>

                      <div class="money-element">
                        Rent
                        <span class="value">{{usDollar(item.rent_proceed)}}</span>
                      </div>

                      <div class="money-element">
                        RefInance EIDL
                        <span class="value">{{usDollar(item.refinance_eidl_proceed)}}</span>
                      </div>

                      <div class="money-element">
                        Debt Interest
                        <span class="value">{{usDollar(item.debt_interest_proceed)}}</span>
                      </div>

                      <p class="font-text-secondary mt-4">
                        <b v-html="item.borrowername"></b> in {{item.borrowercity}}, {{item.borrowerstate}} received a Paycheck Protection Loan of {{this.usDollar(item.currentapprovalamount)}} through Bank of
                        {{item.servicinglendername}}, which was approved in {{toDateString(item.dateapproved)}}.
                      </p>
                      <div class="font-text-secondary">
                        This loan's status is reported by the SBA as "{{item.loanstatus}}", which includes both loans repaid and those fully
                        forgiven from repayment under PPP guidelines. The loan's status was last updated by the SBA in {{toDateString(item.loanstatusdate)}}.
                      </div>

                    </div>
                  </div>
                </div>
                <hr v-show="this.loans.length - 1 !== i" class="divider">
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="business-info">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#business-info-content" aria-expanded="false" aria-controls="business-info-content">
              {{$t("business_information")}}
            </button>
          </h2>
          <div id="business-info-content" class="accordion-collapse collapse" aria-labelledby="business-info"
               data-bs-parent="#accordionWithplusicon">
            <div class="container pb-4 pt-4">
             <div class="row">
               <div class="col-md-12">
                 <div class="font-text-secondary">{{$t("company_details")}}</div>
                 <div class="font-title" v-html="this.loan.borrowername"></div>
                 <div class="font-600-20-30"><span v-html="this.loan.borroweraddress"></span>, {{this.loan.borrowercity}}, {{this.loan.borrowerstate}} {{this.loan.borrowerzip}}</div>
               </div>
             </div>

              <div class="row mt-3">
                <div class=" col-lg-4">
                  <div class="google-map-pan">
                    <p class="text-center text-danger" v-show="!isAvailableGeoLocation">Geo Location is not available</p>
                    <GoogleMap
                        v-show="this.isAvailableGeoLocation"
                        :api-key="this.google_map_api_key"
                        :center="this.center"
                        :zoom="this.zoom"
                        style="height: 100%">
                      <Marker :options="{ position: this.center }"/>
                    </GoogleMap>
                  </div>
                </div>
                <div class=" col-lg-4">
                  <div class="business-info-pan">
                    <div class="business-name">{{$t("business_owner_demographic")}}</div>
                    <div class="d-flex justify-content-between">
                      <div class="p-2">
                        <div class="font-400-12-18">
                          {{$t("race")}}
                        </div>
                        <div class="font-text-secondary">
                          {{$t("unreported")}}
                        </div>
                      </div>
                      <div class="p-2">
                        <div class="font-400-12-18">
                          {{$t("ethnicity")}}
                        </div>
                        <div class="font-text-secondary">
                          {{$t("unreported")}}
                        </div>
                      </div>
                    </div>

                    <div class="d-flex justify-content-between">
                      <div class="p-2">
                        <div class="font-400-12-18">
                          {{$t("gender")}}
                        </div>
                        <div class="font-text-secondary">
                          {{$t("unreported")}}
                        </div>
                      </div>
                      <div class="p-2">
                        <div class="font-400-12-18">
                          {{$t("veteran_status")}}
                        </div>
                        <div class="font-text-secondary">
                          {{$t("unreported")}}
                        </div>
                      </div>
                    </div>
                    <div class="p-2 font-400-12-18">
                      Cuyahoga County, OH — SBA Office 0549 — Congressional District: OH-11
                    </div>
                  </div>
                </div>
                <div class=" col-lg-4">
                  <div class="business-info-pan">
                    <div class="business-name">{{$t("business_demographics")}}</div>
                    <div class="p-2">
                      <div class="font-400-12-18">
                        {{$t("business_age")}}
                      </div>
                      <div class="font-text-secondary">
                        Existing or more than 2 years old
                      </div>
                    </div>

                    <div class="d-flex justify-content-between">
                      <div class="p-2">
                        <div class="font-400-12-18">
                          {{$t("")}}
                        </div>
                        <div class="font-text-secondary">
                          No
                        </div>
                      </div>

                      <div class="p-2">
                        <div class="font-400-12-18">
                          HUB Zone
                        </div>
                        <div class="font-text-secondary">
                          Yes
                        </div>
                      </div>

                      <div class="p-2">
                        <div class="font-400-12-18">
                          Rural/Urban
                        </div>
                        <div class="font-text-secondary">
                          Urban
                        </div>
                      </div>
                    </div>
                    <div class="p-2 font-400-12-18">
                      Cuyahoga County, OH — SBA Office 0549 — Congressional District: OH-11
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="similar-companies">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#similar-companies-content" aria-expanded="false"
                    aria-controls="similar-companies-content">
              Similar Companies near {{this.loan.borrowercity}}
            </button>
          </h2>
          <div id="similar-companies-content" class="accordion-collapse collapse" aria-labelledby="similar-companies"
               data-bs-parent="#accordionWithplusicon">
            <div class="container pb-4">
              <div class="row mt-4">
                <p class="font-text-secondary">
                  In the {{this.loan.borrowercity}} area, {{this.loans.length}} businesses in the "{{this.loan.businesstype}}" industry received a PPP
                  loan.
                </p>
                <p class="font-text-third">
                  {{$t("similar_nearby_businesses_who_received_ppp_funding")}}
                </p>

                <div class="col-md-3 col-lg-3 mb-4" data-bs-toggle="tooltip" data-bs-placement="top" :title="item.borrowername" v-for="(item, index) in this.similarLoans" :key="index">
                  <div class="similar-company-info-pan" @click="OnClickedNearCompany(index, item.id)">
                    <div class="font-text-third" v-html='item.borrowername.length > 27 ? item.borrowername.substring(0, 27)+" . . .": item.borrowername'></div>
                    <div class="size">{{$t("ppp_loan_size")}}</div>
                    <div class="size-value">
                      {{usDollar(item.currentapprovalamount)}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layouts/main";
import {GoogleMap, Marker} from 'vue3-google-map'
import Constants from "@/utils/constants";
import appConfig from '../../../app.config.json';
import {mapGetters, mapActions} from "vuex";
import UtilFunc from "../../utils/functions";
import bootstrapAlert from '@/components/utils/Alert';

import {
  authMethods,
} from "@/state/helpers";

export default {
  page: {
    title: "Company",
    meta: [{
      name: "description",
      content: appConfig.description,
    }],
  },
  name: "detail",
  components: {
    Layout,
    GoogleMap,
    Marker,
    bootstrapAlert
  },
  data(){
    return {
      isAvailableGeoLocation: false,
      loan_id: 0,
      address: "",
      borrowername: '',
      zipcode: '',
      loan:{
        id: 0,
        loannumber: 0,
        dateapproved: 0,
        processingmethod: "",
        borrowername: "",
        borroweraddress: "",
        borrowercity: "",
        borrowerstate: "",
        borrowerzip: "",
        loanstatusdate: "",
        loanstatus: "",
        term: 0,
        currentapprovalamount: 0,
        servicinglendername: "",
        jobsreported: 0,
        naicscode: 0,
        utilities_proceed: 0,
        payroll_proceed: 0,
        mortgage_interest_proceed: 0,
        rent_proceed: 0,
        refinance_eidl_proceed: 0,
        health_care_proceed: 0,
        debt_interest_proceed: 0,
        businesstype: "",
        nonprofit: "",
        forgivenessdate: "",
      },
      google_map_api_key: Constants.GOOGLE_MAP_API_KEY,
      zoom: 15,
    }
  },
  methods: {
    ...authMethods,
    ...mapActions("tracker", ["getMapPosition", "getLoansByName", "getSimilarLoansByZipBusinessType"]),
    ...mapActions("alert", ["setAlert"]),
    toDateString: UtilFunc.toDateString,
    tillDateFromDatetime: UtilFunc.tillDateFromDatetime,
    usDollar: UtilFunc.usDollar,
    selectedLoan(loan){
      this.loan = loan;
      this.address = `${this.loan.borroweraddress} ${this.loan.borrowercity}, ${this.loan.borrowerstate}`;
    },

    async OnClickedNearCompany(index, loan_id){
      this.$isLoading(true);
      try {
        this.loan = this.similarLoans[index];
        await this.getLoansByName({borrowername: this.loan.borrowername, zipcode: this.loan.borrowerzip, loan_id});
        this.address = `${this.loan.borroweraddress} ${this.loan.borrowercity}, ${this.loan.borrowerstate}`;
        await this.getSimilarLoansByZipBusinessType({zip: this.loan.borrowerzip, type: this.loan.businesstype})
            .finally(() => {
              this.$isLoading(false);
            });
        document.title = this.loan.borrowername;

        this.$router.push({
          name: 'detail',
          params: {
            borrowername: this.loan.borrowername,
            zipcode: this.loan.borrowerzip ? this.loan.borrowerzip: 'null',
            id: loan_id
          },
        })

        await this.getMapPosition(this.address)
            .then(() => this.isAvailableGeoLocation = true)
            .catch(() => {this.isAvailableGeoLocation = false});
      }catch (err) {
        this.isAvailableGeoLocation = false;
        this.setAlert({message: err.message, errors: null, color: 'danger'});
      }
    }
  },
  mounted() {
  },
  async created() {
    this.loan_id = this.$route.params.id ? this.$route.params.id : 0;

    this.borrowername = this.$route.params.borrowername;
    this.zipcode = this.$route.params.zipcode;
    this.$isLoading(true);
    try {
      await this.getLoansByName({borrowername: this.borrowername, zipcode: this.zipcode, loan_id: this.loan_id}).finally(() => {
        this.loan = this.loan_id !== 0? this.loans.find(item => item.id == this.loan_id) : this.loans[0];
        document.title = this.loans[0].borrowername;
      });
    }catch (err) {
      this.setAlert({message: err.message, errors: null, color: 'danger'});
    }

    this.address = `${this.loan.borroweraddress} ${this.loan.borrowercity}, ${this.loan.borrowerstate}`;
    try {
      await this.getSimilarLoansByZipBusinessType({zip: this.zipcode, type: this.loan.businesstype})
        .finally(() => {
          this.$isLoading(false);
        });
    }catch (err) {
      this.setAlert({message: err.message, errors: null, color: 'danger'});
    }

    try {
      await this.getMapPosition(this.address)
          .then(() => {this.isAvailableGeoLocation = true})
          .catch(() => {this.isAvailableGeoLocation = false});
    }catch (err) {
      this.isAvailableGeoLocation = false;
      this.setAlert({message: err.message, errors: null, color: 'danger'});
    }
  },
  computed: {
    ...mapGetters("tracker", ["loans", "totalcurrentapprovalamount", "similarLoans", "center"]),
  },
  watch: {}
}
</script>

<style scoped>

</style>